@import 'import';
.PackageCard {
  &.sale {
    position: relative;

    .PackageCard__media .mediaText {
      @include media-breakpoint-up(xl) {
        padding: 40px 30px 45px;
      }
    }

    .SailingCard {
      .price {
        color: $red;
      }

      &:hover {
        background: var(--promo-color-1, $black-violet);

        .price {
          color: $light-orange;
        }
      }
    }
  }

  &__badge {
    position: absolute;
    z-index: 1;
    bottom: -10px;
    left: 0;

    display: flex;
    flex-direction: row-reverse;

    font-size: 1rem;
    font-weight: 700;
    line-height: 1.33;

    > * {
      @include card-box-shadow();

      padding: 8px 16px 8px 34px;
      border-radius: 0 8px 8px;
    }

    .sale {
      color: var(--promo-color-4, $white);
      background: var(--promo-color-1, $vv-purple-light);
    }

    .barTab {
      position: relative;
      z-index: -1;
      top: 0;
      left: -20px;

      color: var(--promo-color-2, $vv-purple-light);

      background: var(--promo-color-3, $white-FB);

      &:only-child {
        left: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      bottom: -22px;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      top: -15px;
      left: 0;

      overflow: hidden;
      flex-direction: column-reverse;
      align-items: stretch;

      width: calc(100% + 10px);
      margin: 0 -5px;

      text-align: center;

      border-radius: 8px;

      @include card-box-shadow();

      > * {
        padding: 4px;
        border-radius: 0;
      }

      .barTab {
        position: static;
      }
    }
  }

  &.portChange {
    min-height: unset;

    .PackageCard__badge {
      bottom: 25px;
    }

    .fullCruiseBtn {
      margin-bottom: 10px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 26px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.withSaleBadge {
      .PackageCard__PortMess {
        padding-top: 12px;
      }
    }
  }
}
